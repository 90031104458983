import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Searches {
    [searchInput: string]: any;
}
interface SearchState {
    searches: Searches;
    searchResultsCount: number;
}

const initialState: Searches = {
    searches: {},
    searchResultsCount: 0,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        storeSearchTerm: (state, action: PayloadAction<{ searchInput: string | null, id: string }>) => {
            state.searches[action.payload.id] = action.payload.searchInput;
        },
        setSearchResultsCount: (state, action: PayloadAction<number>) => {
            state.searchResultsCount = action.payload;
        },
    },
});

export const { storeSearchTerm, setSearchResultsCount } = searchSlice.actions;
export default searchSlice.reducer;