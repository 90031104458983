import React, { useState } from 'react';

interface ListSearchProps {
    placeholder: string;
    id: string;
    onSearch: (searchTerm: string) => void;
}

const ListSearch: React.FC<ListSearchProps> = ({ placeholder, id, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        onSearch(value);
    };

    const onClear = () => {
        setSearchTerm('');
        onSearch('');
    };

    return (
        <div className="form-group search-group search-group-button mb-0">
            <label className="sr-only">Search</label>
            <input
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleSearch}
                id={id}
                className="form-control"
            />
            <span className="clear-search show list-search-clear" onClick={onClear}></span>
        </div>

    );
};

export default ListSearch;
