import CompletePage from "./CompletePage";
import SMBWoman from "../Images/timeAnimationWoman.svg";
import { useState } from "react";

interface Props {
    steps: Step[];
    onCompleteClick: (goToNextStep: () => void) => void;
}

interface Step {
    id: number;
    label: string;
    content: JSX.Element;
    complete: boolean;
    validate?: () => boolean;
}

export default function Guide({ steps, onCompleteClick }: Props) {
    const maxSteps = steps.length;
    const [activeStep, setActiveStep] = useState(1);
    const isComplete = activeStep > maxSteps;
    const isFinalStep = maxSteps === activeStep;
    const isAllStepsComplete = steps.slice(0, -1).every((step) => step.complete);

    const goToStep = (stepId: any) => {
        if (stepId > activeStep) {
            const currentStep = steps[activeStep - 1];
            const isValidStep = currentStep.validate ? currentStep.validate() : true;
            if (!isValidStep) {
                return;
            }
        }

        setActiveStep(stepId);
    };

    const goToPreviousStep = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1);
        }
    };

    const goToNextStep = () => {
        const currentStep = steps[activeStep - 1];
        const isValidStep = currentStep.validate ? currentStep.validate() : true;
        if (isValidStep) {
            setActiveStep(activeStep + 1);
        }
    };

    const renderStepContent = () => {
        return steps.map((step) =>
            activeStep === step.id ? (
                <div className="w-100" key={step.id}>
                    <div className="panel-heading px-96">
                        <h1 className="panel-title">{step.label}</h1>
                    </div>
                    <div>{step.content}</div>
                </div>
            ) : null
        );
    };

    return (
        <>
            <div
                className="panel d-flex justify-content-around flex-column align-items-center py-48 position-relative m-auto"
                style={{width: '70%'}}>
                <div>
                    <h2 className="pb-48 mb-0 text-center">Överföring till Visma eEkonomi</h2>
                    <div className="panel-body w-100">
                        <div className="stepper px-96" style={{maxWidth: "100%"}}>
                            <ul>
                                {steps.map((step, index) => (
                                    <li
                                        key={step.id}
                                        className={`${activeStep === step.id ? 'active' : ''
                                        } ${step.complete ? 'passed' : ''}`}
                                        onClick={() => !isComplete && goToStep(step.id)}
                                        style={{
                                            pointerEvents: steps.slice(0, index).every((step) => step.complete && !isComplete) ? 'auto' : 'none',
                                        }}
                                    >
                                        <div>
                                            <span>{step.label}</span>
                                            <span className="step">Step {step.id}/{maxSteps}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {!isComplete && (
                            <div>
                                <div className="d-flex flex-column align-items-center">
                                    {renderStepContent()}
                                </div>

                                <div className="d-flex justify-content-between px-128">
                                    <button
                                        type="button"
                                        className="btn left"
                                        onClick={goToPreviousStep}
                                        disabled={activeStep === 1}
                                    >
                                        Föregående
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn right ${isFinalStep ? 'btn-primary' : ''}`}
                                        onClick={!isFinalStep ? goToNextStep : () => onCompleteClick(goToNextStep) }
                                        disabled={isFinalStep && !isAllStepsComplete}
                                    >
                                        {isFinalStep ? 'Slutför' : 'Nästa'}
                                    </button>
                                </div>
                            </div>
                        )}
                        {isComplete && <>
                            <CompletePage/>
                            <img src={SMBWoman} className='SMBWoman'/></>}
                    </div>
                </div>
            </div>
        </>
    )
}

