export const RegexLib = {
    'email': '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
    'postalCode': '^[0-9]{5}$',
    'phoneNumber': '^[0-9]{6,}$'
}


export const eAccountingForm = [
    { inputId: 'bookKeepingAccount', rule: 'required', message: 'Bokföringskonto' },
    { inputId: 'bookKeepingMethod', rule: 'required', message: 'Bokföringsmetod' },
    { inputId: 'bank', rule: 'required', message: 'Bank' },
    { inputId: 'vatPeriod', rule: 'required', message: 'Momsperiod' }
]

export const eAccountingBookeepingInformation = [
    { inputId: 'bookKeepingAccount', rule: 'required', message: 'Bokföringskonto' },
    { inputId: 'bookKeepingMethod', rule: 'required', message: 'Bokföringsmetod' },
]

export const eAccountingPaymentInformation = [
    { inputId: 'bank', rule: 'required', message: 'Bank' },
    { inputId: 'vatPeriod', rule: 'required', message: 'Momsperiod' }
]

export const collaborationsForm = [
    { inputId: 'eaccountingVariant', rule: 'required', message: 'Produkt är obligatoriskt' },
    { inputId: 'consultantEmail', rule: RegexLib.email, message: "E-post är inte i rätt format" },
    { inputId: 'invoiceReceiver', rule: 'required', message: 'Fakturamottagare är obligatoriskt' },
    { inputId: 'period', rule: 'required', message: 'Avtalsperiod är obligatoriskt' },
]

export const collaborationsProductInformation = [
    { inputId: 'eaccountingVariant', rule: 'required', message: 'Produkt är obligatoriskt' },
    { inputId: 'consultantEmail', rule: RegexLib.email, message: "E-post är inte i rätt format" }
]

export const collaborationsInvoicingInformation = [
    { inputId: 'invoiceReceiver', rule: 'required', message: 'Fakturamottagare är obligatoriskt' },
    { inputId: 'period', rule: 'required', message: 'Avtalsperiod är obligatoriskt' }
]

export const collaborationPrimaryContact = [
    { inputId: 'firstName', rule: 'required', message: 'Förnamn är obligatoriskt' },
    { inputId: 'lastName', rule: 'required', message: 'Efternamn är obligatoriskt' },
    { inputId: 'mobile', rule: 'required', message: 'Telefon är obligatoriskt' },
    { inputId: 'email', rule: RegexLib.email, message: 'E-post kontakt är inte i rätt format' }
]

export const collaborationInvoicingInfo = [
    { inputId: 'address1', rule: 'required', message: 'Adress är obligatoriskt' },
    { inputId: 'city', rule: 'required', message: 'Stad är obligatoriskt' },
    { inputId: 'postalCode', rule: RegexLib.postalCode, message: 'Postnummer är inte i rätt format' }
]

export const customerInfoForm = [
    // { inputId: 'phoneNumber', rule: RegexLib.phoneNumber, message: 'Telefonnummer är ogiltigt' },
    { inputId: 'bookingAccount', rule: 'required', message: 'Bokföringskonto är obligatoriskt' },
    { inputId: 'bookKeepingMethod', rule: 'required', message: 'Bokföringsmetod är obligatoriskt' },
    { inputId: 'vatPeriod', rule: 'required', message: 'Momsperiod är obligatoriskt' },
    { inputId: 'bank', rule: 'required', message: 'Bank är obligatoriskt' },
];

export const customerInfoLonEa = [
    { inputId: 'startDate', rule: 'required', message: 'Startdatum är obligatoriskt' },
    { inputId: 'endDate', rule: 'required', message: 'Slutdatum är obligatoriskt' },
    { inputId: 'bank', rule: 'required', message: 'Bank är obligatoriskt' },
];