import { useHttp } from "../../Utils/httpUtils";
import { useAppDispatch, useAppSelector, useAuth } from "../hooks";
import {
    storeLogs,
    storeMigrationComments,
    storeInternalMigrations,
    storeInternalMigration,
    storeMigrationCount,
    storeUserActionLog,
    InternalMigrationListModel,
    ErrorsAndWarnings,
    VonCustomer,
    storeVonCustomer,
    UserActionLog,
    MigrationStatusHistory,
    InternalMigration,
    storeMigrationStatusHistory,
    PaginatedResult,
    setInternalMigrations
} from '../Reducers/internalMigrationReducer';
import {storeVonUsers} from "../Reducers/vonReducer";
import { setSortChange } from "../Reducers/paginationReducer";
import { UploadedFile } from "../Components/MigrationUpload";
import { useNavigate } from "react-router-dom";
import { routes } from "../Constants/routes";
import { showAlert } from "../Reducers/alertReducer";
import { useFileActionCreator } from "./FileActionCreator";
import { SourceType } from "../Components/Enums/CommonEnums";
import { Migration } from "../Reducers/migrationReducer";
import { paginationProps } from "../Components/InternalList/Interfaces";

export const useInternalActionCreator = () => {
    const dispatch = useAppDispatch();
    const { request, requestWithBody } = useHttp();
    const pagination = useAppSelector((state) => state.pagination);
    const navigate = useNavigate();

    const fileActions = useFileActionCreator();

    const getMigrationDetailsInternal = (migrationId: string, tab: string = "main") => {
        request(`/api/internal/getMigrationDetails/${migrationId}`, null, (data: InternalMigration) => {
            dispatch(storeInternalMigration(data));
            getInternalDetailsTab(tab, migrationId);
        });
    };

    const getMigrationsInternal = (status:string) => {
        request(`/api/internal/getMigrationList/${status}`, null, (data: any) => dispatch(storeInternalMigrations(data)));
    };

    const getUserActionLog = (migrationId: string) => {
        request(`/api/internal/getUserActionLog/${migrationId}`, null, (data: UserActionLog[]) => dispatch(storeUserActionLog(data)));
    };

    const getMigrationStatusHistory = (migrationId: string) => {
        request(`/api/internal/GetMigrationStatusHistory/${migrationId}`, null, (data: MigrationStatusHistory[]) => dispatch(storeMigrationStatusHistory(data)));
    };

    const getPaginatedMigrationsInternal = (props: paginationProps) => {
        const params = new URLSearchParams();
        params.append("offset", props.offset.toString());
        params.append("sortColumn", props.sortColumn);
        params.append("sortOrder", props.sortOrder);
        params.append("searchPhrase", props.searchPhrase);
        params.append("rowsPerPage", props.rowsPerPage.toString());
        
        request(`/api/internal/getPaginatedMigrationList/${props.status}?${params.toString()}`, null, (data: PaginatedResult<InternalMigrationListModel>) => {
            dispatch(setInternalMigrations(data));
            getMigrationCountByStatus();
            dispatch(setSortChange(false));
        });
    };
    const getCommentsForMigration = (migrationId: string) => {
        dispatch(storeMigrationComments([]));
        request(`/api/internal/getMigrationComments/${migrationId}`, null, (data: any) => dispatch(storeMigrationComments(data)))
    }

    const getVonUsersForMigration = (migrationId: string) => {
        request(`/api/internal/getVonUsers/${migrationId}`, null, (data: any) => dispatch(storeVonUsers(data)))
    }

    const getLogsForMigration = (migrationId: string) => {
        request(`/api/internal/getMigrationLog/${migrationId}`, null, (data: ErrorsAndWarnings[]) => dispatch(storeLogs(data)));
    }

    const addCommentForMigration = (id:string, comment:string) => {

        var addCommentModel = {
            migrationId: id,
            comment: comment,
        };

        var requestObj : RequestInit =  {
            method : 'POST',
            body: JSON.stringify(addCommentModel),
        };

        request(`/api/internal/addMigrationComment`, requestObj, () => getCommentsForMigration(id))
    }

    const changeMigrationStatus = (migrationId: string, status : number) => {
        request(`/api/internal/changeMigrationStatus/${migrationId}/${status}`, {method: 'POST'}, () => getMigrationDetailsInternal(migrationId))
    }

    const assignMigrationToSelf = (migrationId:string) => {
        request(`/api/internal/assignMigration/${migrationId}`, {method: 'POST'}, () => getMigrationDetailsInternal(migrationId))
    }

    const checkCollaboration = (migrationId:string) => {
        request(`/api/internal/checkCollaboration/${migrationId}`, null, () => getMigrationDetailsInternal(migrationId))
    }

    const restartFromPreviousFailStatus = (migrationId:string) => {
        request(`/api/internal/sendMigrationToWorker/${migrationId}`, {method: 'POST'}, () => getMigrationDetailsInternal(migrationId))
    }

    const checkAllWaitingCollaborations = () => {
        request(`/api/internal/checkAllWaitingCollaborations`, {method: 'POST'}, () => getMigrationsInternal("collaborations"));
    }

    const getMigrationCountByStatus = () => {
        request(`/api/internal/getMigrationCountForStatus`, null, (data: any) => dispatch(storeMigrationCount(data)))
    }

    const deleteMigration = (migrationId : string) => {
        request(`/api/internal/delete/${migrationId}`, {method: 'DELETE'}, () => {
            navigate(routes.internalList);
        });
    }

    const deleteMigrations = (migrationIds: string[], callback?: Function) => {

        const httpRequest = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(migrationIds),
        };
        
        request(`/api/internal/delete/`, httpRequest, (json: any) => callback && callback());
    }


    // This is used when user clicks on Update button ta make sure the current tab will be updated with new data.
    const getInternalDetailsTab = (tab: string, migrationId: string) => {
        switch (tab) {
            case "von":
                getVonUsersForMigration(migrationId);
                break;
            case "comments":
                getCommentsForMigration(migrationId);
                break;
            case "log":
                getLogsForMigration(migrationId);
                break;
            case "userActions":
                getUserActionLog(migrationId);
                break;
            case "migrationStatusHistory":
                getMigrationStatusHistory(migrationId);
                break;
            default:
                break;
        }
    };

    const downloadfileFromBlob = (data: Blob, fileName: string, callback?: Function) => {
        const blob = new Blob([data], { type: "application/octet-stream" });

        if(callback) {
            callback(blob, fileName);
            return;
        }

        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        document.body.removeChild(link);
    }

    const uploadFile = (migrationId: string, file: any) : void => {

        var data = new FormData();
        data.append("file", file)

        requestWithBody(`/api/internal/UploadFile/${migrationId}`, {
            method: "post",
            body: data,
        }, () => {
            dispatch(showAlert({title: "Uppladdad", message: "Filen har laddats upp", style: "top", type: "success"}));
            fileActions.getFiles(migrationId);
        });
    }

    const downloadFile = (migrationId: string, file: UploadedFile, callback?: Function): void => {

        request(`/api/internal/GetPresignedDownloadUrl/${migrationId}/${file.id}`, null, (signedUrl: string) => {
            request(signedUrl, {
                method: "get",
                headers: { Accept: "application/octet-stream", 'Content-Type': "application/octet-stream"}}, (data: Blob) => {
                downloadfileFromBlob(data, file.filename, callback);
            }, false, file.filename);
        });
    }

    const downloadCsvTemplate = (templateName: string, fileName: string): void => {

        const requestObj: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Accept': 'application/octet-stream'
            },
        };

        request(`/api/internal/DownloadCsvTemplate/${templateName}`, requestObj, (data: Blob) => {
            downloadfileFromBlob(data, fileName);
        });
    }

    const startImportFiles = (migrationId: string, files: UploadedFile[], sourceType: SourceType, callback: Function) => {

        var requestModel = {
            migrationId: migrationId,
            files: files.map(f => f.id),
            sourceType: sourceType
        };

        var requestObj : RequestInit =  {
            method : 'POST',
            body: JSON.stringify(requestModel),
        };

        request(`/api/internal/StartImportFiles`, requestObj, (data: any) => {
            callback(data);
        });
    }

    const searchVonCustomer = (customerNumber: string) => {
        request(`/api/internal/searchVonCustomer/${customerNumber}`, null, (data: VonCustomer) => dispatch(storeVonCustomer(data)));
    }

    const createMigration = (sourceType: SourceType, customerNumber: string) => {

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify({sourceType: sourceType, customerNumber})
        }

        // This will create a new migration for current signed in user and comapny
        request(`/api/internal/createmigration`, httpRequest, (data: Migration) => {
            getMigrationDetailsInternal(data.id);
            navigate(routes.internalMigrationDetails(data.id));
            console.log(data);
        });
    }

    return {
        getMigrationDetailsInternal,
        getMigrationsInternal,
        getCommentsForMigration,
        getVonUsersForMigration,
        getLogsForMigration,
        addCommentForMigration,
        changeMigrationStatus,
        assignMigrationToSelf,
        checkCollaboration,
        restartFromPreviousFailStatus,
        checkAllWaitingCollaborations,
        getPaginatedMigrationsInternal,
        downloadFile,
        uploadFile,
        getUserActionLog,
        getMigrationStatusHistory,
        deleteMigration,
        deleteMigrations,
        downloadCsvTemplate,
        startImportFiles,
        createMigration,
        searchVonCustomer
    }
}