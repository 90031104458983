import React from 'react';
import { SmbMigration } from "../Reducers/userReducer";
import { MigrationStatus, MigrationStatus as migrationStatus, SourceType } from './Enums/CommonEnums';
import LoadingIcon from "./Icons/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { routes } from "../Constants/routes";
import { current } from '@reduxjs/toolkit';


export default function MigrationStatusDetails(props: { migration: SmbMigration }) {
    const migration = props.migration;

    const manualMigrationStatuses: MigrationStatus[] = [
        migrationStatus.FilesUploaded,
        migrationStatus.Manual,
        migrationStatus.Done
    ]

    const adminMigrationStatuses: MigrationStatus[] = [
        migrationStatus.FilesUploaded,
        migrationStatus.ReadyForMigration,
        migrationStatus.PrepareFiles,
        migrationStatus.TranslateFiles,
        migrationStatus.SaveDataToEAccounting,
        migrationStatus.Done
    ]

    const getRelevantStatuses = (): MigrationStatus[] => {

        switch (migration.sourceType) {
            case SourceType.CsvFiles:
                return manualMigrationStatuses;
            default:
                return adminMigrationStatuses;
        }
    }

    const relevantStatuses: MigrationStatus[] = getRelevantStatuses();

    const statusLabel: { [key: number]: string } = {
        [migrationStatus.New]: 'Ny',
        [migrationStatus.FilesUploaded]: 'Laddar upp data',
        [migrationStatus.WaitingForCollaboration]: 'Väntar på samarbete',
        [migrationStatus.ReadyForMigration]: 'Redo för påbörja migration',
        [migrationStatus.PrepareFiles]: 'Förbereder filer',
        [migrationStatus.TranslateFiles]: 'Översätter filer',
        [migrationStatus.ReuploadFiles]: 'Ladda upp filer igen',
        [migrationStatus.CreateVbAccount]: 'Skapar VB-konto',
        [migrationStatus.CreateVonAccount]: 'Skapar VON-konto',
        [migrationStatus.OrderEAccounting]: 'Beställer eEkonomi',
        [migrationStatus.CreateCollaboration]: 'Skapar samarbete',
        [migrationStatus.PendingCollaboration]: 'Pågående samarbete',
        [migrationStatus.SaveDataToEAccounting]: 'Överför data till Visma eEkonomi',
        [migrationStatus.Manual]: 'Manuell',
        [migrationStatus.Done]: 'Överföring klar',
        [migrationStatus.OnboardingCompleted]: 'Överföring klar',
        [migrationStatus.GdprClean]: 'GDPR Rensning',
        [migrationStatus.GdprCleanFailed]: 'GDPR Rensning misslyckades',
        [migrationStatus.GdprCleanManual]: 'GDPR Rensning manuell',
        [migrationStatus.AuthenticationFailed]: 'Autentisering misslyckades',
        [migrationStatus.Error]: 'Fel'
    };

    const currentStatus = props.migration.currentStatus;
    const failedStatus = props.migration.failedStatus;

    const migDone = migrationStatus.Done;
    const readyMig = migrationStatus.ReadyForMigration;
    const prepFiles = migrationStatus.PrepareFiles;
    const translateFiles = migrationStatus.TranslateFiles;
    const migError = migrationStatus.Error;

    const checkingDataCollection: MigrationStatus[] = [
        readyMig, prepFiles, translateFiles
    ];

    const getStatusDetails = (status: MigrationStatus) => {
        // Check if the status is included in checkingDataCollection
        const includeStatus = checkingDataCollection.includes(status);
        const includeFailed = checkingDataCollection.includes(failedStatus);
        const includeCurrent = checkingDataCollection.includes(currentStatus);

        // Check if we are going to show error icon or text
        const statusError = currentStatus === migError && status === failedStatus;
        const statusErrorCheckingData = currentStatus === migError && includeStatus && includeFailed;

        // Check if status is pending
        const statusPending = currentStatus === status;
        const statusPendingCheckingData = includeCurrent && includeStatus;

        if (statusError || statusErrorCheckingData) {
            return {
                icon: <span className="vismaicon vismaicon-filled vismaicon-error"></span>,
                statusText: migration.additionalInfoCount > 0 ? 'Stoppad - Komplettering behövs' : 'Stoppad - Hanteras av Visma Spcs'
            };
        }

        if ((statusPending || statusPendingCheckingData) && currentStatus !== migDone) {
            return {
                icon: <LoadingIcon />,
                statusText: 'Hanteras just nu'
            };
        }

        if ((status < failedStatus)
            || (failedStatus === 0 && status < currentStatus && currentStatus < migError)
            || (includeStatus && currentStatus > translateFiles)
            || (currentStatus === migDone)
            || (currentStatus === migrationStatus.OnboardingCompleted)) {
            return {
                icon: <span className="vismaicon vismaicon-filled vismaicon-success"></span>,
                statusText: 'Klar'
            };
        }

        return {
            icon: <span className="vismaicon vismaicon-filled vismaicon-success disabled"></span>,
            statusText: 'Ej startad'
        };
    }

    return (
        <div className="table-responsive pt-24">
            <table className="table table-relaxed">
                <tbody>
                    {relevantStatuses.map((status, index) => {
                        if (checkingDataCollection.includes(status)) {
                            if (index === relevantStatuses.findIndex(s => checkingDataCollection.includes(s))) {
                                const { icon, statusText } = getStatusDetails(translateFiles);
                                return (
                                    <tr key={`kontroll-av-data-${index}`}>
                                        <td>
                                            <div className="d-flex flex-row align-items-center">
                                                <div>
                                                    {icon}
                                                </div>
                                                <div className="pl-12">
                                                    {statusText}
                                                </div>
                                            </div>
                                        </td>
                                        <td>Kontroll av data</td>
                                    </tr>
                                );
                            } else {
                                return null;
                            }
                        }
                        const { icon, statusText } = getStatusDetails(status);
                        return (
                            <tr key={status}>
                                <td>
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            {icon}
                                        </div>
                                        <div className="pl-12"
                                        >
                                            {statusText}
                                        </div>
                                    </div>
                                </td>

                                <td>{statusLabel[status]}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}